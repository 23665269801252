<template>
  <div>
    <el-dialog title="Refund of Deposit" :visible.sync="show" width="640px" @open="open">
      <div class="content">
        <el-form :model="forms" label-width="200px">
          <el-form-item label="Return to Tenant">
            <el-input :disabled="flog" v-model="forms.tenant" type="number">
              <strong slot="prefix">$</strong>
            </el-input>
          </el-form-item>
          <el-form-item label="Pay to Homeowner">
            <el-input :disabled="flog" v-model="forms.homeowner" type="number">
              <strong slot="prefix">$</strong>
            </el-input>
          </el-form-item>
          <el-form-item label="Pay to Other">
            <el-input :disabled="flog" v-model="forms.other_pay" type="number">
              <strong slot="prefix">$</strong>
            </el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: center; margin-top: 30px">
          <el-button
            :disabled="flog"
            class="primary-button"
            @click="checkRequest"
            :loading="loading"
            >Submit</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { apiReturnSecurity } from "../../../API/api";
function keepTwoDecimal(num) {
  return Math.round(parseFloat(num) * 100) / 100; // 采用四舍五入，保存两位小数
}
function calcTotalPrice(cost) {
  return keepTwoDecimal(
    Object.values(cost).reduce((previous, current) => {
      return previous + parseFloat(current);
    }, 0)
  );
}
export default {
  props: ["form", "amount", "id", "status", "addressT","addressH"],
  data() {
    return {
      show: false,
      loading: false,
      flog: false,
      forms:{},
    };
  },
  methods: {
    open(){
      this.forms = Object.assign({},this.form)
    },
    checkRequest() {
      let obj = {
        other_pay: this.forms.other_pay ? this.forms.other_pay : 0,
        homeowner: this.forms.homeowner ? this.forms.homeowner : 0,
        tenant: this.forms.tenant ? this.forms.tenant : 0,
      };
      if (parseFloat(this.amount) < calcTotalPrice(obj)) {
        this.$message.warning("Cannot exceed the total amount of the deposit.");
      } else {
          this.loading = true;
        this.forms.amount_held = parseFloat(this.amount - calcTotalPrice(obj));
        apiReturnSecurity(this.id, { return_info: this.forms }).then(() => {
            this.form.other_pay = this.forms.other_pay;
            this.form.homeowner = this.forms.homeowner;
            this.form.tenant = this.forms.tenant;
            this.show = false;
            this.flog = true;
            this.$emit("returnSecurity");
            this.$message.success("Deposit returned successfully");
          }).catch(err=>{
            this.$message.error(err.response.data.detail?err.response.data.detail:"")
          }).finally(() => {
              this.loading = false;
            });
        
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./CommonDialog.scss";
</style>