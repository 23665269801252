<template>
  <div>
    <div class="row top-box">
      <p class="lease-title">Lease Payment Report</p>
      <el-input v-model="search" @keyup.enter.native="searchInfor" placeholder="Search by Address, Owner, Tenant, Lease # or Email" ></el-input>
    </div>
    <div class="filter-class">
      <!-- <el-switch v-model="flag" active-color="#678993" inactive-text="Security Deposit NOT Submitted" @change="fiflterSD" >
      </el-switch> -->
      <el-popover popper-class="placement-class" placement="bottom" class="popup" width="660" trigger="click" v-model="popoverShow">
          <div class="content">
            <Search-Filter class="pc" ref="child" @close="close" @precisionSearch="precisionSearch"></Search-Filter>
          </div>
          <el-button slot="reference" class="filter-btn mt-2">
            <img src="../../../assets/icon/ico-filter.svg" alt />
            <span>Filter</span>
          </el-button>
        </el-popover>
    </div>
  </div>
</template>
<script>
import SearchFilter from "./SearchFilter.vue"
export default {
  components:{ SearchFilter },
  data() {
    return {
      search: "",
      name: "",
      flag: false,
      popoverShow: false,
    };
  },
  methods: {
    close(){ 
      this.popoverShow = false;
      this.popupVisible = false; 
    },
    precisionSearch(val){ this.$emit("leaseReportSearchFilter", val); },
    searchInfor() {
      this.$emit("searchInfor", this.search);
    },
    fiflterSD() {
      this.$emit("fiflterSD", this.flag);
    },
  },
  created() {
    this.name = sessionStorage.getItem("name");
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/init.scss";
.top-box {
  justify-content: space-between;
  margin:0px;

  .lease-title {
    text-align: left;
    font-size: 24px;
    font-family: $fontRB;
  }
  /deep/ .el-input {
    width: 35%;
    min-width: 300px;
  }
  @media (max-width: 992px) {
    display: block;
    text-align: left;
    /deep/ .el-input {
      width: 100%;
    }
  }
}
/deep/ .el-switch__label.is-active {
  color: #303133;
}
.filter-class{
  text-align: right;
  margin-bottom: 10px;
  .filter-btn{
    background-color: #fff;
    margin-right: 10px;
    width: 110px;
    text-align: center;
    height: 47px;
    /deep/ span {
      width: 80px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }
  }
}
</style>