<template>
  <div class="table">
    <table width="100%">
       <tr>
        <th>SECURITY DEPOSIT</th>
        <th>TOTAL</th>
        <th>RECEIVED</th>
        <th min-width="100px">DATE</th>
        <th>REFERENCE (TENANT)</th>
        <th>REFERENCE (HOMEOWNER)</th>
      </tr>
      <tr>
        <td>Incoming</td>
        <td>{{data.amount | currencyPrice("$ ")}}</td>
        <td>{{data.amount_received | currencyPrice("$ ")}}</td>
        <td>{{data.return_date | dateFormat}}</td>
        <td class="setting-width">
          <el-input v-model="data.return_info.sd_tenant_bill_payment_ref" @change="updateTenantBill" v-if="$store.state.userInfo.is_admin === true"/>
          <span v-else>{{JSON.stringify(data.return_info)!='{}'&& data.return_info!=undefined&&data.return_info.sd_tenant_bill_payment_ref?data.return_info.sd_tenant_bill_payment_ref:""}}</span>
        </td>
        <td class="setting-width">
          <el-input v-model="data.return_info.sd_homeowner_bill_payment_ref" @change="updateHomeownerBill" v-if="$store.state.userInfo.is_admin === true"/>
          <span v-else>{{JSON.stringify(data.return_info)!='{}'&&data.return_info!=undefined&&data.return_info.sd_homeowner_bill_payment_ref?data.return_info.sd_homeowner_bill_payment_ref:""}}</span>
        </td>
      </tr>
      <tr>
        <td>Outgoing</td>
        <td>Return Authorization</td>
        <td colspan="4"><a v-if="data.status!= undefined &&data.status!='Paid'" class="returnAuthorization" @click="$refs.securitydeposit.show = true">Submit Return Authorization</a></td>
      </tr>
    </table>
    <div class="show-infor">
      <p class="row"><span>Return to Tenant</span><span>{{JSON.stringify(data.return_info) === '{}'||!data.return_info.tenant ?0.00:data.return_info.tenant | currencyPrice("$ ")}}</span></p>
      <p class="row"> <span>Tenant Mailing Address</span><span>{{infor.tenant_info.street}} {{infor.tenant_info.city}} {{infor.tenant_info.state}}</span></p>
      <p class="row"><span>Pay to Homeowner</span><span>{{JSON.stringify(data.return_info) === '{}'||!data.return_info.homeowner?0.00:data.return_info.homeowner | currencyPrice("$ ")}}</span></p>
      <p class="row"><span>Homeowner Mailing Address</span><span>{{infor.owner_info.street}} {{infor.owner_info.city}} {{infor.owner_info.state}} </span></p>
      <p class="row"><span>Pay to Other</span><span>{{JSON.stringify(data.return_info) === '{}'||!data.return_info.other_pay ?0.00:data.return_info.other_pay | currencyPrice("$ ")}}</span></p>
      <p class="row"><span>Amount Held by C$C</span><span>{{JSON.stringify(data.return_info) === '{}'?data.amount:data.return_info.amount_held | currencyPrice("$ ")}}</span></p>
      <Note :noteData="commonList" :lease_id="infor.lease_id" :total="total" @pagesChanged="pagesChanged" ></Note>
    </div>
    <Security-Deposit-Dialog ref="securitydeposit" @returnSecurity="returnSecurity" :form="data.return_info" :addressH="infor.owner_info.street" :addressT="infor.tenant_info.street" :amount="data.amount" :id="infor.lease_id" :status="data.status"></Security-Deposit-Dialog>
  </div>
</template>
<script>
import SecurityDepositDialog from "./SecurityDepositDialog";
import { apiUpdateSDQBInfor, apiGetSecurityDepositComment } from "../../../API/api"
import Note from "../../leases/SecurityDeposit/SecurityDepositNote.vue";
export default {
  components:{ SecurityDepositDialog, Note },
  props:["data", "infor","commonList", "total"],
  data() {
    return {
      current: 1,
    }
  },
  computed: {
    offset() {
      return (this.current - 1) * 5;
    },
    query() {
      return {
        offset: this.offset,
        limit: 5,
      };
    },
  },
  methods:{
    updateTenantBill(){
      apiUpdateSDQBInfor({security_deposit:this.data.lease,sd_tenant_bill_payment_ref:this.data.return_info.sd_tenant_bill_payment_ref}).then(res=>{
          this.$message.success("success")
      }).catch(err=>{
          this.$message.error(err.response.data.detail?err.response.data.detail:"")
      })
    },
    updateHomeownerBill(){
      apiUpdateSDQBInfor({security_deposit:this.data.lease,sd_homeowner_bill_payment_ref:this.data.return_info.sd_homeowner_bill_payment_ref}).then(res=>{
          this.$message.success("success")
      }).catch(err=>{
          this.$message.error(err.response.data.detail?err.response.data.detail:"")
      })
    },
    returnSecurity() {
      let a=this.data
      a.status = "Paid";
      this.$set(this.data, a);
    },
    // 获取保证金备注
    getSecurityComment() {
      apiGetSecurityDepositComment(this.infor.lease_id, this.query).then(
        (res) => {
          this.commonList = res.results;
          this.total = res.count;
        }
      );
    },
    pagesChanged(number) {
      this.current = number;
      this.getSecurityComment();
    },
  }
};
</script>
<style lang="scss" scoped>
@import "./table.scss";
.table {
  margin-top: 40px;
  table {
    border-collapse: collapse;
    width: 100%;
    td{
      &:first-of-type{
        width: 15%;
        padding-left: 10px;
      }
    }
    th {
      background-color:rgba(56,0,255,0.1);
      &:first-of-type {
        padding-left: 10px;
        border-left: 4px solid 	#3800FF;
      }
    }
    .setting-width{
      max-width: 0.142857rem;
      /deep/ .el-input{ max-width: 90%; }
      &:last-child{/deep/ .el-input{ max-width: 100%; }}
    }
    .returnAuthorization{
      color: #D4AF37;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .show-infor{
    margin-left: 17%;
    left: 20%;
    .row{
      span{
        &:first-of-type{
          display: inline-block;
          width: 270px;
        }
      }
    }
    /deep/ .table .el-table th .cell{border-color: #fff;background-color: #fff;}
  }
}
</style>