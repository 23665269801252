<template>
  <div class="table payments-table">
    <el-table :data="data">
      <el-table-column label="INCOMING PAYMENTS" width="200">
        <template slot-scope="scope">
          <span>Payment {{ scope.row.period }}</span>
        </template>
      </el-table-column>
      <el-table-column label="TOTAL">
        <!-- 如果是co_broke，就显示佣金（ the commission (15%)） -->
        <template slot-scope="scope">  {{ infor.is_co_broke_representing_tenant?Number(scope.row.disbursement_form.agent_commission)+Number(scope.row.disbursement_form.office_commission):scope.row.total_amount | currencyPrice("$ ") }}</template>
      </el-table-column>
      <el-table-column label="RECEIVED">
        <template slot-scope="scope">
          
          <div v-if="type == true">
            <a v-if="scope.row.amount_received == 0.00" class="receive-payment" @click="openPaymentCheck(scope.row)">Receive Payment</a>
            <!-- 如果不是支票支付的话，不可以修改 -->
            <p v-else-if="scope.row.status!= undefined &&scope.row.status==='Paid'&&scope.row.payment_method!='Check'"><span>{{ infor.is_co_broke_representing_tenant?Number(scope.row.disbursement_form.agent_commission)+Number(scope.row.disbursement_form.office_commission):scope.row.amount_received | currencyPrice("$ ") }}</span></p>
            <p v-else><span>{{ infor.is_co_broke_representing_tenant?Number(scope.row.disbursement_form.agent_commission)+Number(scope.row.disbursement_form.office_commission):scope.row.amount_received | currencyPrice("$ ") }}</span> <i class="el-icon-edit" @click="openPaymentCheck(scope.row)"></i></p>
          </div>
          <p v-else>{{ scope.row.amount_received | currencyPrice("$ ") }}</p>
        </template>
      </el-table-column>
      <el-table-column label="BALANCE">
        <template slot-scope="scope">
          <p> {{ infor.is_co_broke_representing_tenant?scope.row.status==='Paid'?0:Number(scope.row.disbursement_form.agent_commission)+Number(scope.row.disbursement_form.office_commission):scope.row.balance | currencyPrice("$ ") }} </p>
        </template>
      </el-table-column>

      <el-table-column label="DATE">
        <template slot-scope="scope">
          <p v-if="scope.row.status!= undefined &&scope.row.status==='Past Due'" style="color: #DE1B4B;">Due {{scope.row.due_date | dateFormat}} <a v-loading="remind" element-loading-spinner="el-icon-loading" class="send-reminder" @click="sendReminderToTenant">Send Reminder</a></p>
          <span v-else-if="scope.row.status!= undefined &&scope.row.status==='Paid'" style="color: #37a000;">Rec'd {{scope.row.payment_date? scope.row.payment_date:null | dateFormat}}</span>
          <p v-else>{{scope.row.due_date | dateFormat}}<a class="send-reminder" v-loading="remind" element-loading-spinner="el-icon-loading" @click="sendReminderToTenant">Send Reminder</a></p>
        </template>
      </el-table-column>
      <el-table-column label="REFERENCE">
        <template slot-scope="scope">
          <span v-if="scope.row.import_id">{{scope.row.import_id}}</span>
          <template v-else>
             <el-input @change="updateReference(scope.row)" v-model="scope.row.reference" v-if="type == true"/>
<!--             <span v-if="scope.row.status==='Paid'&&scope.row.payment_method!='Check'">{{scope.row.payment_method}}</span>-->
             <span v-else>{{scope.row.reference}}</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="Check Request">
        <template slot-scope="scope">
          <p v-if="scope.row.disbursement_form && scope.row.disbursement_form.submit_at!=null" @click="openRequestStatements(scope.row)"><span>{{ scope.row.disbursement_form.submit_at | dateFormat }}</span></p>
          <a v-else class="sbumit-now" @click="openRequestStatements(scope.row)">Submit Now</a>
        </template>
      </el-table-column>
    </el-table>
    <Request-Statements ref="request" :form="statement.disbursement_form" :status="statement.status" :id="statement.payment_uuid" :amount="statement.total_amount" :owner_infor="owner_info" @updateSubmitAt="updateSubmitAt"></Request-Statements>
    <Payment-Check ref="check" :is_broke="infor.is_co_broke_representing_tenant" :data="checkInfo" @createCheck="createCheck"></Payment-Check>
  </div>
</template>
<script>
import { apiSendNotificationAgent, apiUpdateCheckPayment } from "../../../API/api";
import RequestStatements from "./RequestStatements"
import  storage from '../../../js/storage'
import PaymentCheck from './PaymentCheck'
export default {
  props: ["data", "id", "owner_info","infor"],
  components: { RequestStatements, PaymentCheck },
  data() {
    return {
      statement:{},
      checkInfo:{},
      remind:false,
      type:"",
    }
  },
  methods: {
    updateReference(val){
      apiUpdateCheckPayment(val.payment_uuid,{reference:val.reference}).then(res=>{
        this.data.forEach((item, index) => {
          if (val.payment_uuid=== item.payment_uuid) {
            let a = this.data[index];
            a.reference=res.reference;
            this.$set(this.data, index, a);
          } 
      });
        this.$message.success('Modified successfully');
      }).catch(err => {
          this.$message.error(err.response.data.detail);
        })
    },
    openPaymentCheck(val){
      this.checkInfo = val
      this.$refs.check.show = true
    },
    // 表单
    openRequestStatements(val){
      this.statement = val;
      setTimeout(() => {
      this.$refs.request.show = true;
      this.$refs.request.getNote()
      }, 300);
      
    },
    // 发送邮件通知租客
    sendReminderToTenant() {
      // this.remind = true;
      apiSendNotificationAgent({ lease: this.id }).then((res) => {
        this.$message.success("Successfully sent reminder to tenant");
      }).finally(()=>{
        this.remind = false;
      });
    },
    updateSubmitAt(val,id){
      this.data.forEach((item, index) => {
          if (id=== item.payment_uuid) {
            let a = this.data[index];
            a.disbursement_form.submit_at = val.submit_at;
            this.$set(this.data, index, a);
          } 
      });
    },
    createCheck(res,data){
      this.data.forEach((item, index) => {
          if (data.payment_uuid=== item.payment_uuid) {
            let a = this.data[index];
            a.amount_received = res.amount;
            a.payment_date = res.payment_date;
            a.reference=res.reference;
            this.$set(this.data, index, a);
          } 
      });
    }
  },
  created(){
    this.type = storage.getItem("isType")
  }
};
</script>
<style lang="scss" scoped>
@import "./table.scss";
.payments-table {
  /deep/ .el-table th {
    .cell {
      background-color: rgba(55, 160, 0, 0.1);
    }
    &:first-of-type {
      border-left: 4px solid #37a000;
    }
  }
  .sbumit-now{
    color: #D4AF37;
    text-decoration: underline;
  }
  .send-reminder ,.receive-payment{
    color: #678993;
    font-family: "Roboto-Medium";
    font-size: 12px;
    text-decoration: underline;
  }
  .receive-payment,.el-icon-edit{
    color: #3800FF;
    font-size: 14px;
  }
  .el-icon-edit{
    margin-left: 5px;
  }
}
</style>