<template>
  <div>
    <el-dialog  title="Check Request Form" :visible.sync="show" width="640px" >
      <div class="content" v-if="form">
        <el-form :model="form" label-width="200px">
          <el-form-item label="Processing Fee">
            <el-input :disabled="form.submit_at?true:false" v-model="form.processing_fee" type="number">
              <strong slot="prefix">$</strong>
            </el-input>
          </el-form-item>
          <el-form-item label="Occupancy Taxes">
            <el-input :disabled="form.submit_at?true:false" v-model="form.tax" type="number">
              <strong slot="prefix">$</strong>
            </el-input>
          </el-form-item>
          <el-form-item label="Agent Commission">
            <el-input :disabled="form.submit_at?true:false" v-model="form.agent_commission" type="number">
              <strong slot="prefix">$</strong>
            </el-input>
          </el-form-item>
          <el-form-item label="Office Commission">
            <el-input :disabled="form.submit_at?true:false" v-model="form.office_commission" type="number">
              <strong slot="prefix">$</strong>
            </el-input>
          </el-form-item>
          <el-form-item label="Co-broker Commission">
            <el-input :disabled="form.submit_at?true:false" v-model="form.co_broke_commission" type="number">
              <strong slot="prefix">$</strong>
            </el-input>
          </el-form-item>
          <el-form-item label="Rent to Homeowner">
            <el-input :disabled="form.submit_at?true:false" v-model="form.rent_to_owner" type="number">
              <strong slot="prefix">$</strong>
            </el-input>
          </el-form-item>
          <el-form-item label="Payee Name"> {{owner_infor&&owner_infor.payee_name?owner_infor.payee_name:null}}</el-form-item>
          <el-form-item label="Mailing Address"> {{owner_infor&&owner_infor.street?owner_infor.street + ",":null}} {{owner_infor.city?owner_infor.city+ ",":null}} {{owner_infor.state?owner_infor.state+ ",":null}} {{owner_infor.zip_code?owner_infor.zip_code:null}}</el-form-item>
          <el-form-item label="Fees to Homeowner">
            <el-input :disabled="form.submit_at?true:false" v-model="form.other_fee" type="number">
              <strong slot="prefix">$</strong>
            </el-input>
          </el-form-item>
          <el-form-item label="Security Deposit">
            <el-input :disabled="form.submit_at?true:false" v-model="form.security_deposit" type="number">
              <strong slot="prefix">$</strong>
            </el-input>
          </el-form-item>
          <el-form-item label="Total">
            <span>{{total | currencyPrice("$ ") }}</span>
          </el-form-item>
        </el-form>
        <p class="note-title">Notes</p>
        <Note :noteData="data" :total="totalNote" ref="note" @createNote="createNote" @pagesChanged="pagesChanged" v-loading="loading">
            <template slot="table_btn">
            <el-table-column width="50">
              <template slot-scope="scope">
                <i style="color: #f56c6c" class="del-btn el-icon-delete" @click="openDelNoteDialog(scope.row.comment_uuid)"></i>
              </template>
            </el-table-column>
          </template>
           <template slot="note_btn">
            <div class="add_note"><a @click="$refs.note.show = true">+ Add Note</a></div>
          </template>
        </Note>
        <div style="text-align: center;margin-top:20px">
            <el-button class="primary-button" :loading="loading" :disabled="form.submit_at!=null?true:false" @click="checkRequest">Submit Check Request Form</el-button>
            <el-button class="primary-button" @click="downloadCompletedForm" :loading="loadingDownload">Download Completed Form</el-button>
        </div>
      </div>
    </el-dialog>
    <Del-Note @del="delNote" :title="title" ref="delInfor"></Del-Note>
  </div>
</template>
<script>
import { apiUpdateDisbursement, apiCreateDisbursementForm, apiDelDisbursementForm, apiGetDisbursementForm, apiDownloadDisbursement } from "../../../API/api";
import { downloadFile } from "../../../API/utils";
function keepTwoDecimal(num) { // 采用四舍五入，保存两位小数
  return Math.round(parseFloat(num) * 100) / 100;
}
function calcTotalPrice(cost) {
  return keepTwoDecimal(
    Object.values(cost).reduce((previous, current) => {
      return previous + parseFloat(current);
    }, 0)
  );
}
import  storage from '../../../js/storage'
import Note from "../../common/Note"
import DelNote from "../../common/DelDialog";
export default {
  components: { Note, DelNote },
  props:[ "form","owner_infor","amount","id" ,"status"],
  data() {
    return {
      show: false,
      loading:false,
      totalAmount:0,
      data:[],
      totalNote: 0,
      current: 1,
      loading: false,
      isType: "",
      note_id: "",
      title:"Are you sure to delete this note?",
      loadingDownload: false,
    };
  },
  computed:{
    total(){
      let obj = {
        processing_fee: this.form.processing_fee,
        tax: this.form.tax,
        agent_commission: this.form.agent_commission,
        office_commission: this.form.office_commission,
        co_broke_commission: this.form.co_broke_commission,
        rent_to_owner: this.form.rent_to_owner,
        other_fee: this.form.other_fee,
        security_deposit: this.form.security_deposit,
      }

      this.totalAmount = calcTotalPrice(obj)
      return this.totalAmount
    },
    query(){
      return {
        limit:5,
        offset: this.offset
      }
    },
    offset(){
      return (this.current - 1) * 5;
    }
  },
  methods:{
    checkRequest(){
      if(this.status != undefined &&this.status === 'Paid'){
        let obj = {};
        obj = Object.assign({}, this.form);
        if (this.totalAmount === Number(this.amount)) {
        delete obj.disbursement_form_uuid;
          if (!obj.agent_commission) {
            obj.agent_commission = 0.0;
          }
          if (!obj.office_commission) {
            obj.office_commission = 0.0;
          }
          if (!obj.other_fee) {
            obj.other_fee = 0.0;
          }
          if (!obj.processing_fee) {
            obj.processing_fee = 0.0;
          }
          if (!obj.rent_to_owner) {
            obj.rent_to_owner = 0.0;
          }
          if (!obj.security_deposit) {
            obj.security_deposit = 0.0;
          }
          if (!obj.co_broke_commission) {
            obj.co_broke_commission = 0.0;
          }
          if (!obj.tax) {
            obj.tax = 0.0;
          }
          if(obj.agent_commission<0||obj.office_commission<0||obj.other_fee<0||obj.processing_fee<0||obj.rent_to_owner<0||obj.security_deposit<0||obj.co_broke_commission<0||obj.tax<0){
          this.$message.error("Negatitve figures not allowed. ")
          }else{
            this.loading = true;
            apiUpdateDisbursement( this.form.disbursement_form_uuid, obj ) .then((res) => {
                this.$message.success("Submit disbursement form successful");
                this.$emit("updateSubmitAt",res,this.id)
              })
              .catch((err) => {
                this.$message.error("Submit disbursement form fail. "+ err.response.data.detail?err.response.data.detail:null);
              })
              .finally(() => {
                this.loading = false;
                this.show = false;
              });
            }
          } else {
          this.$message.warning("Amount mismatch");
        }
      }else {
        this.$message.warning("Not paid, you could not submit this payment.")
      }
      
    },
    openDelNoteDialog(id) {// 删除评论
      this.note_id = id;
      this.$refs.delInfor.show = true;
    },
    delNote() {
       this.$refs.delInfor.loading = true;
      apiDelDisbursementForm(this.id, this.note_id).then((res) => {
        this.data.forEach((item, index) => {
          if (this.note_id === item.comment_uuid) {
            this.data.splice(index, 1);
            this.$refs.delInfor.show = false;
          }
        });
      }).finally(()=>{ this.$refs.delInfor.loading = false; });
    },
    getNote(){
        apiGetDisbursementForm(this.id,this.query).then(res=>{
          this.data = res.results;
          this.totalNote = res.count;
        })
    },
    pagesChanged(number){
        this.current = number;
        this.getNote()
    },
    createNote(note){
        this.loading = true;
        this.$refs.note.loading = true;
        apiCreateDisbursementForm(this.id,{content:note}).then(res=>{
          this.current = 1;
          this.$refs.note.note=""
          this.getNote()
          this.$refs.note.show = false;
        }).finally(()=>{this.loading = false; this.$refs.note.loading = false; })
    },
    downloadCompletedForm(){
       if(this.form.submit_at){
         this.loadingDownload = true;
          apiDownloadDisbursement({disbursement_form: this.form.disbursement_form_uuid}).then(res=>{
          downloadFile( res.data, res.headers["content-disposition"].split("=")[1], "application/pdf" );
       }).finally(()=>{
         this.loadingDownload = false;;
       })
       }else {
         this.$message.error("Please submit Check Request Form first.")
       }
      
    }
  },
  created(){
     this.isType = storage.getItem('isType')
  }
};
</script>
<style lang="scss" scoped>
@import "../../../css/dialog.scss";
@import "../../../css/button.scss";
.content {
   margin-top: 20px;
  /deep/ .el-form-item {
      margin-bottom: 10px;
    .el-form-item__label {
      text-align: left;
    }
    .el-input__inner{
        padding-left: 40px;
        width: 160px;
    }
    .el-input__prefix{
        display: inline-block;
        width: 30px;
        height: calc(100% - 4px);
        background-color: #F4F9FC;
        margin: 2px 0 0 0;
    }
  }
  .note-title{
      text-align: left;
      font-family: "Roboto-Bold";
      font-size: 18px;
      color: #36425D;
  }
  /deep/ input[type="number"] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  -moz-appearance: textfield;
  }
  .add_note a{
    text-decoration: underline;
    color: #678993;
    cursor: pointer;
  }
  /deep/ .el-table th{
    .cell{
      background-color: #fff;
    }
    &:first-of-type{
     border-left: none;
  }}
}
</style>