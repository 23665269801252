<template>
    <div class="">
    <el-dialog title="Receive Payment" :visible.sync="show" width="500px" @open="open" @closed="closed">
      <div class="content">
        <el-form :model="form" ref="checkForm" :rules="rules" label-width="150px">
          <el-form-item label="Date Received" prop="payment_date" :rules="rules.required">
            <el-date-picker v-model="form.payment_date"  type="date" placeholder="" format="MM/dd/yyyy" value-format="yyyy-MM-dd"> </el-date-picker>
          </el-form-item>
          <el-form-item label="Payment Amount" prop="amount" :rules="rules.required">
            <el-input v-model="form.amount" type="number">
              <strong slot="prefix">$</strong>
            </el-input>
          </el-form-item>
          <el-form-item label="Reference" prop="reference" :rules="rules.required">
            <el-input v-model="form.reference" />
          </el-form-item>
        </el-form>
         <p v-if="data.amount_received==='0.00'">Email sent to agent on:  &nbsp;&nbsp;&nbsp;Not sent yet</p>
         <p v-else>Email sent to agent on: &nbsp;&nbsp;&nbsp; {{data.check_request_at? data.check_request_at : null | dateFormat}}</p>
       
        <div style="text-align: center; margin-top: 30px">
          <el-button class="primary-button" @click="checkPaper" :loading="loading">{{data.amount_received==="0.00"?"Send Check Request to Agent":"Save"}}</el-button>
          
        </div>
      </div>
    </el-dialog>
    </div>
</template>
<script>
import { apiCreateCheckPayment, apiUpdateCheckPayment } from "../../../API/api";
import dayjs from "dayjs"
export default {
    props:[ "data" ,"is_broke"],
    data() {
        return {
            show: false,
            loading:false,
            form:{
                payment: "",
                payment_date:"",
                amount: 0,
                reference:""
            },
            rules:{
              required: [
                {
                  required: true,
                  message: "this information is required",
                  trigger: ["blur"],
                },
              ],
            }
        }
    },
    methods:{
      open(){
        this.form.payment_date = this.data.payment_date?this.data.payment_date:dayjs().format("YYYY-MM-DD");
        this.form.amount = this.is_broke?Number(this.data.disbursement_form.agent_commission)+Number(this.data.disbursement_form.office_commission):this.data.amount_received===0.00?this.data.amount_received:this.data.total_amount;
        this.form.reference= this.data.reference;
        this.form.payment = this.data.payment_uuid
      },
      closed(){
        this.clear();
      },
      clear(){
        this.form.payment_date = this.form.reference = this.form.payment = "";
        this.form.amount = 0
      },
       checkPaper(){
         this.$refs.checkForm.validate((valid) => {
           if(valid){
              this.loading = true
              if(this.data.amount_received==="0.00"){//创建
                apiCreateCheckPayment(this.form).then(res => {
                this.$emit("createCheck",this.form,this.data)
                this.$message.success('success');
                this.clear();
              }).catch(err => {
                this.$message.error(err.response.data.detail);
              }).finally(()=>{
                  this.show = false
                  this.loading = false;
              });
              }else{//修改
                apiUpdateCheckPayment(this.data.disbursement_form.disbursement_form_uuid,this.form).then(res=>{
                  this.$message.success('Modified successfully');
                  this.$emit("createCheck",this.form,this.data)
                  this.clear();
                  }).catch(err => {
                  this.$message.error(err.response.data.detail);
                  }).finally(()=>{
                    this.show = false;
                    this.loading = false;
                  });
              }
           }else{
             return false;
           }
         })
        
        
       },
    }
}
</script>
<style lang="scss" scoped>
/deep/.el-form-item__label::before{display: none}
@import "./CommonDialog.scss";
</style>