<template>
  <div class="table">
    <table width="100%">
      <tr>
        <th>OUTGOING PAYMENTS</th>
        <th>TYPE</th>
        <th>PAYEE</th>
        <th>TOTAL</th>
        <th>DATE</th>
        <th>REFERENCE</th>
      </tr>
      <!--Agent Commission  -->
      <tr  v-if="JSON.stringify(leaseAgent) != '{}'">
        <td>Payment {{leaseAgent.period}}</td>
        <td>Commission</td>
        <td>Leasing Agent</td>
        <td>{{leaseAgent.disbursement_form?leaseAgent.disbursement_form.agent_commission:null | currencyPrice("$ ")}}</td>
        <td>
          <el-date-picker v-if="($store.state.userInfo.is_admin === true)" @change="changeQBAgentDate(leaseAgent)" v-model="leaseAgent.qb_info.agent_bill_payment_date" type="date" placeholder="" format="MM/dd/yyyy" value-format="yyyy-MM-dd"> </el-date-picker>
          <span v-else>{{leaseAgent.qb_info.agent_bill_payment_date}}</span>
          </td>
        <td>
          <el-input @change="changeQBAgent(leaseAgent)" v-model="leaseAgent.qb_info.agent_bill_payment_ref" v-if="($store.state.userInfo.is_admin === true)"/>
          <span v-else>{{leaseAgent.qb_info.agent_bill_payment_ref}}</span>
        </td>
      </tr>
      <tr v-for="(item, index) in datas" :key="index">
        <td> Payment {{ index + 1 }} </td>
        <td> Rent</td>
        <td> Homeowner </td>
        <td> {{ item.disbursement_form != null ? parseFloat(item.disbursement_form.rent_to_owner + item.disbursement_form.other_fee) : null | currencyPrice("$ ") }} </td>
        <td>
          <el-date-picker v-if="($store.state.userInfo.is_admin === true)" @change="changeQBHomeownerDate(item)" v-model="item.qb_info.homeowner_bill_payment_date"  type="date" placeholder="" format="MM/dd/yyyy" value-format="yyyy-MM-dd"> </el-date-picker>
          <span v-else>{{item.qb_info.homeowner_bill_payment_date}}</span>
        </td>
        <td>
          <el-input @change="changeQBHomeowner(item)" v-model="item.qb_info.homeowner_bill_payment_ref" v-if="($store.state.userInfo.is_admin === true)"/>
          <span v-else>{{item.qb_info.homeowner_bill_payment_ref }}</span>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import { apiUpdateQBInfor } from "../../../API/api";
export default {
  props: ["data"],
  computed:{
    leaseAgent(){
      let obj = {}
      for (let i = 0; i < this.data.length; i++) {
          if(this.data[i].disbursement_form&&this.data[i].disbursement_form.agent_commission!=0.00){
            if(this.data[i].qb_info.agent_bill_payment_ref&&this.data[i].qb_info.agent_bill_payment_ref.indexOf(':')!=-1){
              this.data[i].qb_info.agent_bill_payment_ref=this.data[i].qb_info.agent_bill_payment_ref.split(":")[1]
            }
          obj = this.data[i];
          break;
        }
      }
      return obj;
    },
    datas(){
      let arr = [];
      this.data.forEach(item => {
        if(item.qb_info.homeowner_bill_payment_ref&&item.qb_info.homeowner_bill_payment_ref.indexOf(':')!=-1){
          item.qb_info.homeowner_bill_payment_ref = item.qb_info.homeowner_bill_payment_ref.split(":")[1]
        }
        arr.push(item)
      });
      return arr
    }
  },
  methods:{
    changeQBAgentDate(data){
      let obj = {
        payment: data.payment_uuid,
        agent_bill_payment_date: data.qb_info.agent_bill_payment_date ? data.qb_info.agent_bill_payment_date : null
      };
      this.changeQB(obj)
    },
    changeQBAgent(data){
      let obj = {
        payment: data.payment_uuid,
        agent_bill_payment_ref: data.qb_info.agent_bill_payment_ref ? data.qb_info.agent_bill_payment_ref : null
      };
      this.changeQB(obj)
    },
    changeQBHomeownerDate(data){
      let obj = {
        payment: data.payment_uuid,
        homeowner_bill_payment_date: data.qb_info.homeowner_bill_payment_date  ? data.qb_info.homeowner_bill_payment_date : null,
      };
      this.changeQB(obj)
    },
    changeQBHomeowner(data){
      let obj = {
        payment: data.payment_uuid,
        homeowner_bill_payment_ref: data.qb_info.homeowner_bill_payment_ref  ? data.qb_info.homeowner_bill_payment_ref : null,
      };
      this.changeQB(obj)
    },
    changeQB(obj){
      apiUpdateQBInfor(obj)
        .then((res) => {
          this.$message.success("Success");
        })
        .catch((err) => {
          this.$message.error(err.response.data.detail);
        });
    }
  }
};
</script>
<style lang="scss" scoped>
// @import "./table.scss";
.table {
  margin-top: 40px;
  table {
    border-collapse: collapse;
    td{
      &:first-of-type{
        padding-left: 10px;
        width: 200px;
      }
      &:nth-last-of-type(2){
        min-width: 200px;
        width: 20%;
        padding-right: 20px;
      }
      &:last-of-type{
        min-width: 200px;
        width: 20%;
        padding-right: 10px;
      }
    }
    th {
      background-color: 	rgba(222,27,75,.1);
      &:first-of-type {
        padding-left: 10px;
        border-left: 4px solid 	rgba(222,27,75,1.000);
      }
    }
  }
}
</style>