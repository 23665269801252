<template>
  <div class="lease">
    <el-table ref="table" :data="data"  @sort-change="sort" @expand-change="expandChange" @cell-click="handle" :row-key="getRowKeys"
      :expand-row-keys="expands"
      style="width: 100%"
    >
      <el-table-column label="LEASE" prop="lease_id" sortable="custom" width="100">
      </el-table-column>
      <el-table-column label="STATUS" width="120" prop="status" sortable="custom">
        <template slot-scope="scope">
          <span
            v-if="scope.row.status!= undefined&&scope.row.status === 'Paid in Full'"
            class="paid-in-full"
            >{{ scope.row.status!= undefined?scope.row.status:"" }}</span
          >
          <span v-else-if="scope.row.status!= undefined&&scope.row.status === 'Past Due'" class="past-due">{{
            scope.row.status!= undefined?scope.row.status:"" 
          }}</span>
          <span v-else-if="scope.row.status!= undefined&&scope.row.status === 'Unsigned'" class="unsigned">{{
            scope.row.status!= undefined?scope.row.status:"" 
          }}</span>
          <span v-else-if="scope.row.status!= undefined&&scope.row.status === 'Draft'" class="draft">{{
            scope.row.status!= undefined?scope.row.status:"" 
          }}</span>
          <span v-else-if="scope.row.status!= undefined&&scope.row.status === 'Current'" class="current">{{
           scope.row.status!= undefined?scope.row.status:"" 
          }}</span>
          <span
            v-else-if="scope.row.status!= undefined&&scope.row.status === 'Cancelled'"
            class="cancelled"
            >{{ scope.row.status!= undefined?scope.row.status:""  }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="LEASE DATES" min-width="140px">
        <template slot-scope="scope">
          <span >{{ scope.row.arrival_date | dateFormat("MM/DD") }} - {{ scope.row.departure_date | dateFormat() }}</span>
        </template>
      </el-table-column>
      <el-table-column label="TENANT" prop="tenant_last_name" sortable="custom" min-width="100px">
      </el-table-column>
      <el-table-column label="OWNER" prop="owner_last_name" sortable="custom"  min-width="100px"> </el-table-column>
      <el-table-column label="RENTAL ADDRESS" prop="listing_address" sortable="custom" min-width="150" >
      </el-table-column>
      <el-table-column label="AGENT" prop="user_first_name"  sortable="custom">
        <template slot-scope="scope">
          <span>{{ scope.row.user_first_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="PAYMENT 1">
        <el-table-column label="Rec'd" width="65">
          <template slot-scope="scope">
            <span class="circle" v-if="scope.row.payments.length>=1&&scope.row.payments[0]" :class="{ mature: scope.row.payments[0].status != undefined && scope.row.payments[0].status === 'Paid', }"
            ></span>
          </template>
        </el-table-column>
        <el-table-column label="Paid" width="50">
          <template slot-scope="scope">
            <span class="circle mature" v-if="scope.row.payments.length>=1&&scope.row.payments[0].qb_info != undefined && scope.row.payments[0].qb_info.agent_bill_payment_date && scope.row.payments[0].qb_info.homeowner_bill_payment_date" 
            ></span>
            <span class="circle hang" v-else-if="scope.row.payments.length>=1&&scope.row.payments[0].qb_info != undefined && (scope.row.payments[0].qb_info.agent_bill_payment_date || scope.row.payments[0].qb_info.homeowner_bill_payment_date)"></span>
            <span class="circle" v-else></span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="PAYMENT 2">
        <el-table-column label="Rec'd" width="65">
          <template slot-scope="scope">
            <span
              v-if="scope.row.payments.length>=2&&scope.row.payments[1]"
              class="circle" :class="{ mature:  scope.row.payments[1].status != undefined &&  scope.row.payments[1].status === 'Paid', }"
            ></span>
          </template>
        </el-table-column>
        <el-table-column label="Paid" width="50">
          <template slot-scope="scope">
            <span
              v-if="scope.row.payments.length>=2&&scope.row.payments[1]"
              class="circle"
              :class="{  mature:scope.row.payments[1].qb_info != undefined &&scope.row.payments[1].qb_info.homeowner_bill_payment_date }"
            ></span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="PAYMENT 3">
        <el-table-column label="Rec'd" width="65">
          <template slot-scope="scope">
            <span
              v-if="scope.row.payments.length>=3&&scope.row.payments[2]"
              class="circle"
              :class="{
                mature:scope.row.payments[2].status  &&scope.row.payments[2].status === 'Paid'}"
            ></span>
          </template>
        </el-table-column>
        <el-table-column label="Paid" width="50">
          <template slot-scope="scope">
            <span
              v-if="scope.row.payments.length>=3&&scope.row.payments[2]"
              class="circle"
              :class="{mature:scope.row.payments[2].qb_info != undefined &&scope.row.payments[2].qb_info.homeowner_bill_payment_date}"
            ></span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="PAYMENT 4">
        <el-table-column label="Rec'd" width="65">
          <template slot-scope="scope">
            <span
              class="circle"
              v-if="scope.row.payments.length>=4&&scope.row.payments[3]"
              :class="{ mature:scope.row.payments[3].status != undefined &&scope.row.payments[3].status === 'Paid'}"
            ></span>
          </template>
        </el-table-column>
        <el-table-column label="Paid" width="50">
          <template slot-scope="scope">
            <span
              class="circle"
              v-if="scope.row.payments.length>=4&&scope.row.payments[3]"
              :class="{mature:scope.row.payments[3].qb_info != undefined &&scope.row.payments[3].qb_info.homeowner_bill_payment_date}"
            ></span>
          </template>
        </el-table-column>
      </el-table-column>
        <el-table-column label="SD"  width="70" prop="security_deposit__status" sortable="custom">
          <template slot-scope="scope">
            <span  class="circle mature" v-if="scope.row.security_deposit_status==='Paid'"></span>
            <span v-else class="circle"></span>
          </template>
      </el-table-column>
      <el-table-column type="expand" width="100">
        <template slot-scope="scope">
          <div v-loading="loading">
            <Payments-Table
              :data="scope.row.payments"
              :owner_info="scope.row.owner_info"
              :id="scope.row.lease_id"
              :infor="scope.row"
            >
            </Payments-Table>
            <Payment-Status
              :data="scope.row.payments"
              :id="scope.row.lease_id"
            ></Payment-Status>
            <Security-Deposit
               ref="child" :commonList="commonList" :total="totalSecurity"
              :data="scope.row.security_deposit"
              :infor="scope.row"
            ></Security-Deposit>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Page
      :total="total"
      :pageSize="25"
      @number="pagesChanged"
      :currentPage="currentPage"
    ></Page>
  </div>
</template>
<script>
import PaymentsTable from "./PaymentsTable";
import PaymentStatus from "./PaymentStatus";
import SecurityDeposit from "./SecurityDeposit";
import Page from "../../common/Paging";
import { apiGetSecurityDepositComment } from "../../../API/api";
export default {
  components: { PaymentsTable, PaymentStatus, SecurityDeposit, Page },
  props: ["currentPage", "total", "data"],
  data() {
    return {
      expandedRows: [], //展示行id的数据
      expands: [],
      getRowKeys(row) {
        return row.lease_id;
      },
      lease: {},
      loading: false,
      commonList:[],
      totalSecurity: null,
    };
  },
  methods: {
    sort(val){ //排序
      this.$emit("sort", val);
    },
       // 获取保证金备注
    getSecurityComment(id) {
      apiGetSecurityDepositComment(id, {offset: 0,limit: 5,}).then(
        (res) => {
          this.commonList = res.results;
          this.totalSecurity = res.count;
        }
      );
    },
    // hand方法是用按钮控制显示隐藏
    // expandChange方法当前用小图标控制显隐
    expandChange(row, expandedRows) {
      if (expandedRows.length) {
        this.expands = [];
        if (row) {
          this.expands.push(row.lease_id);
          // this.getLease(row.lease_id);
           this.getSecurityComment(row.lease_id);
        }
      } else {
        this.expands = [];
      }
    },
    handle(row, column, event) {
      //row 点击这行的数据
      //column.property 点击列的标题
      if (column.property == "lease_id") {
        //如果点击不同列展开不同数据则用 column.property 判断即可
        this.$router.push({ name: "edit-lease", params: { id: row.lease_id } });
      } else {
        this.$refs.table.toggleRowExpansion(row); //切换某一行的展开状态
        
      }
    },
    // 获取房屋详情
    // getLease(id) {
    //   this.loading = true;
    //   apiGetLease(id)
    //     .then((res) => {
    //       this.data.forEach((item, index) => {
    //         if (id === item.lease_id) {
    //           let a = this.data[index];
    //           a.lease = res;
    //           this.$set(this.data, index, a);
    //         }

    //       });
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // },
    pagesChanged(number) {
      this.$emit("pagesChanged", number);
    },
  },
  watch:{
    data(){
      if (this.$route.query.lid){
        this.$refs.table.toggleRowExpansion(this.data[0]);
      }
    }
  },
  mounted() {
    if (this.$route.query.lid) {
      this.expands.push(this.$route.query.lid);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/table.scss";
.lease {
  background-color: #fff;
  margin-bottom: 50px;
  // 表格expand
  /deep/ .el-table .cell {
    word-wrap: break-word;
    word-break: break-word;
  }
  /deep/ .el-table--border td,
  /deep/ .el-table--border th {
    border-right: none;
  }
  /deep/ .el-table__expand-icon {
    &::before {
      content: "Payments";
      color: #678993;
      font-family: "Roboto-Bold";
    }
    .el-icon {
      left: 90%;
      color: #678993;
    }
  }
  /deep/ .el-table__expand-icon.el-table__expand-icon--expanded {
    transform: rotate(0deg);
    .el-icon {
      transform: rotate(90deg);
    }
  }
  .paid-in-full,
  .draft,
  .past-due,
  .unsigned,
  .current,
  .cancelled {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    width: 86px;
    text-align: center;
    border-radius: 4px;
    color: #37a000;
    background-color: rgba(55, 160, 0, 0.1);
  }
  .draft {
    color: #fa6400;
    background-color: rgba(250, 100, 0, 0.1);
  }
  .cancelled {
    color: #de1b4b;
    background-color: rgba(222, 27, 75, 0.1);
  }
  .unsigned {
    color: #f7b500;
    background-color: rgba(247, 181, 0, 0.1);
  }
  .current {
    color: #005ff7;
    background-color: rgba(0, 95, 247, 0.1);
  }
  .past-due {
    color: rgba(241, 26, 213, 1);
    background-color: rgba(241, 26, 213, 0.1);
  }
  .circle {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid #3a4660;
  }
  .mature {
    border-color: #37a000;
    background-color: #37a000;
  }
  .hang{
    border-color: #D4AF37;
    background-color: #D4AF37;
  }
  }
</style>