<template>
  <div class="lease">
    <Lease-Repost-Top @searchInfor="searchInfor" @fiflterSD="fiflterSD" @leaseReportSearchFilter="leaseReportSearchFilter"></Lease-Repost-Top>
    <Lease-Table
      @sort="sort"
      :total="total"
      @pagesChanged="pagesChanged"
      :currentPage="currentPage"
      :data="data"
      v-loading.fullscreen.lock="loading"
    ></Lease-Table>
  </div>
</template>
<script>
import { apiGetLeases } from "../../API/api";
import LeaseTable from "./Component/LeaseTable";
import LeaseRepostTop from "./Component/LeaseRepostTop";
import storage from "../../js/storage";
export default {
  components: { LeaseTable, LeaseRepostTop },
  data() {
    return {
      total: 0,
      currentPage: 1,
      limit: 25,
      data: [],
      loading: false,
      search: "",
      ordering:"",
      searchData:{ security_deposit_status:[]},
    };
  },
  computed: {
    offset() {
      return (this.currentPage - 1) * this.limit;
    },
    query() {
      return {
        limit: this.limit,
        offset: this.offset,
        self: storage.getItem("isType") ? false : true,
        show_payments: true,
        show_security_deposit: true,
        show_owner_info: true,
        show_tenant_info: true,
        // exclude_past_lease: true,
        search: this.search,
        show_cancelled: true,
        ordering: this.ordering,
        lease_id: this.searchData.lease_id,
        security_deposit_status: this.searchData.security_deposit_status,
        tenant_last_name: this.searchData.tenant_last_name,
        tenant_email: this.searchData.tenant_email,
        listing_address: this.searchData.listing_address,
        user_last_name: this.searchData.user_last_name,
        status: this.searchData.status,
      };
    },
  },

  methods: {
    leaseReportSearchFilter(val){
      this.searchData = Object.assign({},this.searchData,val)
      this.currentPage = 1;
      this.data = []
      this.getLeases();
    },
    fiflterSD(flag){
      if(flag ){
        this.flag =["Current","Past Due"];
      }else{
       this.flag = [];
      }
      this.data = []
      this.getLeases();
    },
    sort(val) {
      this.currentPage = 1;
      if (val.order === "ascending") {
        this.ordering = val.prop;
      } else if (val.order === "descending") {
        this.ordering = "-" + val.prop;
      } else {
        this.ordering = "";
      }
      this.data = []
      this.getLeases();
    },
    pagesChanged(number) {
      this.currentPage = number;
      this.getLeases();
    },
    searchInfor(val) {
      this.currentPage = 1;
      this.search = val;
      this.getLeases();
    },
    // 获取leases列表
    getLeases() {
      this.loading = true;
      apiGetLeases(this.query)
        .then((res) => {
          this.total = res.count;
          this.data = res.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    if (this.$route.query.lid) this.search = this.$route.query.lid;
    this.getLeases();
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/init.scss";
</style>